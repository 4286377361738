@import 'variables'

// **********
// Animations
// **********
@mixin keyframes($animation-name)
  // (1) animation-name, (2) animation-duration,
  // (3) animation-timing-function, (4) animation-delay,
  // (5) animation-iteration-count, (6) animation-direction,
  // (7) animation-fill-mode, (8) animation-play-state.
  @-webkit-keyframes #{$animation-name}
    @content

  @keyframes #{$animation-name}
    @content

@mixin animation($str)
  -webkit-animation: #{$str}
  animation: #{$str}

@include keyframes(fade-in)
  0%
    opacity: 0
  100%
    opacity: 1

@include keyframes(osc-float)
  0%
    transform: scale(1)
  20%
    transform: scale(1.05) rotate(-5deg)
  40%
    transform: scale(1) rotate(0)
  60%
    transform: scale(1.05) rotate(5deg)
  100%
    transform: scale(1) rotate(0)

@include keyframes(oscillate1)
  0%
    transform: translateX(0)
  25%
    transform: translateX(-3px)
  75%
    transform: translateX(3px)
  100%
    transform: translateX(0)

@include keyframes(scale-tilt-left)
  0%
    transform: scale(1)
  25%
    transform: scale(1.1) rotate(-5deg)
  50%
    transform: scale(1.05) rotate(0deg)
  75%
    transform: scale(1.1) rotate(-5deg)
  100%
    transform: scale(1) rotate(0deg)

@include keyframes(scale-tilt-right)
  0%
    transform: scale(1)
  25%
    transform: scale(1.1) rotate(5deg)
  50%
    transform: scale(1.05) rotate(0deg)
  75%
    transform: scale(1.1) rotate(5deg)
  100%
    transform: scale(1) rotate(0deg)

@include keyframes(rise-up)
  0%
    transform: scale(1) translate3d(0 0 0)
  20%
    transform: scale(0.95) translate3d(0 20px 0)
  90%
    transform: scale(1.1) translate3d(0 -20px 0)
  100%
    transform: scale(1.05) translate3d(0 -20px 0)

@include keyframes(pulse)
  0%
    transform: scale(1)
  50%
    transform: scale(1.1)
  100%
    transform: scale(1)

@include keyframes(pulse-1)
  0%
    transform: scale(1)
  50%
    transform: scale(1.05)
  100%
    transform: scale(1)

@include keyframes(slight-pulse)
  0%
    transform: scale(1)
  50%
    transform: scale(1.02)
  100%
    transform: scale(1)

@include keyframes(slide-in-left)
  0%
    opacity: 0
    transform: translateX(-20px)
  100%
    opacity: 1
    transform: translateX(0)

@include keyframes(slide-out-left)
  0%
    opacity: 1
    transform: translateX(0)
  100%
    opacity: 0
    transform: translateX(-20px)

@include keyframes(appear-in)
  0%
    opacity: 0
    transform: scale(0.9)
  100%
    opacity: 1
    transform: scale(1)

@include keyframes(appear-in-bounce)
  0%
    opacity: 0
    transform: scale(0.9)
  80%
    opacity: 1
    transform: scale(1.1)
  100%
    opacity: 1
    transform: scale(1)

@include keyframes(super-zoom-in)
  0%
    opacity: 1
    transform: scale(1)
  10%
    transform: scale(0.95)
  100%
    opacity: 0
    transform: scale(4)

// ***********
// Positioning
// ***********
@mixin flex-center
  display: flex
  align-items: center
  justify-content: center

@mixin mintedverse-layers
  width: 100vw
  height: calc(100vw * 0.5625)
  background-size: cover
  background-repeat: no-repeat
  background-position: center

@mixin mintedverse-position
  position: absolute
  top: 0
  left: 0

@mixin mintedverse-quadrant
  position: absolute
  top: 0
  left: 0
  min-width: 400px
  min-height: 338px
  width: 100%
  height: calc(100vw * 0.28125)

@mixin planet-thumb($width, $height, $top, $left)
  background-size: contain
  background-repeat: no-repeat
  position: absolute
  width: $width
  height: $height
  top: $top
  left: $left

  @media (max-width: $breakpoint-max-lg)
    width: $width * 0.75
    height: $height * 0.75

  @media (max-width: $breakpoint-max-md)
    width: $width * 0.65
    height: $height * 0.65

// ************
// Interactions
// ************
@mixin transition-standard
  transition: all 0.2s ease

@mixin hover
  @include transition-standard
  animation: none
  transform: translateY(-2px)
  cursor: pointer

@mixin active
  @include transition-standard
  animation: none
  transform: translateY(1px)
  cursor: pointer

// *****************
// Responsive images
// *****************
@mixin large-images($image:'')
  @media only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 320px), only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 320px), only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 320px), only screen and (        min-device-pixel-ratio: 2)      and (min-width: 320px), only screen and (                min-resolution: 192dpi) and (min-width: 320px), only screen and (                min-resolution: 2dppx)  and (min-width: 320px)
    /* Small screen, retina, stuff to override above media query */
    background-image: url($image)

  @media only screen and (min-width: 700px)
    /* Medium screen, non-retina */
    background-image: url($image)

@mixin xlarge-images($image:'')
  @media only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 700px), only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 700px), only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 700px), only screen and (        min-device-pixel-ratio: 2)      and (min-width: 700px), only screen and (                min-resolution: 192dpi) and (min-width: 700px), only screen and (                min-resolution: 2dppx)  and (min-width: 700px)
    /* Medium screen, retina, stuff to override above media query */
    background-image: url($image)

  @media only screen and (min-width: 1300px)
    /* Large screen, non-retina */
    background-image: url($image)

  @media only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1300px), only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1300px), only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1300px), only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1300px), only screen and (                min-resolution: 192dpi) and (min-width: 1300px), only screen and (                min-resolution: 2dppx)  and (min-width: 1300px)
    /* Large screen, retina, stuff to override above media query */
    background-image: url($image)

// ***************
// Global Elements
// ***************
@mixin custom-scrollbar($bg-color: '', $handle-color: '')
  scrollbar-color: #{$handle-color} #{$bg-color}

  &::-webkit-scrollbar
    background-color: #0a0000
    background-color: #{$bg-color}
    width: 8px
    height: 8px

    &:hover
      background-color: #0a0000
      background-color: #{$bg-color}

  // Track
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-corner
    background-color: #0a0000
    background-color: #{$bg-color}

  // Handle
  &::-webkit-scrollbar-thumb
    background: #c49956
    background: #{$handle-color}
    border-radius: 10px

    &:hover
      background: $pale-yellow
      background: #{$handle-color}

@mixin gradient-text
  background: -webkit-linear-gradient($bright-yellow, $pale-yellow)
  -webkit-background-clip: text
  -webkit-text-fill-color: transparent
