/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@400;500;600;700&display=swap')

/***********************/
/***** Breakpoints *****/
/***********************/
$breakpoint-min-xl:  1200px
$breakpoint-max-lg:  1199px
$breakpoint-min-lg:  992px
$breakpoint-max-md:  991px
$breakpoint-min-md:  768px
$breakpoint-max-sm:  767px
$breakpoint-min-sm:  576px
$breakpoint-max-xs:  575px
$breakpoint-max-xxs: 400px

/*******************/
/***** Colours *****/
/*******************/
$white: #FFF
$medium-grey: #686E7C
$dark-grey: #181818
$black: #000
$bright-green: #D0E4A3
$alarm: #F123CD
$bright-yellow: #E8D388
$pale-yellow: #C49956
$backdrop-blue: rgba(5, 13, 59, 1)
$bright-rose: #FF2D67

/**********************************/
/***** Font Related Variables *****/
/**********************************/
$regular: 400
$medium: 500
$bold: 600
$heavy: 700

$border-line: solid 1px rgba($medium-grey, 0.5)