/* You can add global styles to this file, and also import other style files */
@import 'mixins'
@import 'variables'

*
  @include custom-scrollbar

html
  background-color: $black
  font-family: 'Axiforma', 'Montserrat', sans-serif
  -webkit-font-smoothing: antialiased

body
  background-color: $black
  font-family: 'Axiforma', 'Montserrat', sans-serif
  font-weight: $medium
  -webkit-font-smoothing: antialiased
  color: $dark-grey
  font-size: 1em
  line-height: 1.5em
  letter-spacing: 0.3px
  margin: 0
  width: 100vw
  min-height: 100vh

app-quadrant
  width: 100vw
  min-height: 100vh
  position: fixed

a,
.link
  text-decoration: underline
  font-weight: $bold
  color: $bright-yellow
  transition: all 0.2 ease
  transform: translateY(0)
  display: inline-block

  &:hover
    cursor: pointer
    color: $bright-yellow
    transform: translateY(-1px)
    transition: all 0.2s all

  &:active
    transform: translateY(0)
    transition: all 0.2s all
    color: $pale-yellow

  &:not([href])
    color: $bright-yellow

    &:hover
      cursor: pointer
      color: $bright-yellow

    &:not([class])
      color: $bright-yellow

      &:hover
        cursor: pointer
        color: $bright-yellow

      &:active
        transform: translateY(0)
        transition: all 0.2s all
        color: $pale-yellow

.inline-block
  display: inline-block

button
  -webkit-appearance: none
  display: inline-block
  background-color: $pale-yellow
  padding: 16px 24px 15px
  border: 0
  color: $white
  border-radius: 60px
  -webkit-border-radius: 60px
  -moz-border-radius: 60px
  font-weight: $medium
  font-size: 1em
  font-family: 'Axiforma', 'Montserrat', sans-serif

  &:disabled
    opacity: 0.5

  &:hover
    cursor: pointer
    background-color: $black
    color: $pale-yellow
    transform: translateY(-2px)
    transition: all 0.2s ease

  &:active
    cursor: pointer
    transform: translateY(0)

  &.secondary
    background-color: transparent
    border: solid 2px $pale-yellow
    color: $pale-yellow

    &:hover
      border-color: transparent
      background-color: $black
      color: $pale-yellow

    &:focus
      solid: solid 2px $pale-yellow !important

  &.btn-link
    background-color: transparent
    color: $bright-yellow

    &:hover,
    &:active
      text-decoration: none

h1,
.h1
  @include gradient-text
  font-family: 'Axiforma', 'Montserrat', sans-serif
  font-size: 1.75em
  font-weight: $bold
  color: $pale-yellow
  text-align: left

  @media (max-width: $breakpoint-max-xs)
    font-size: 1.6em

  @media (max-width: $breakpoint-max-xxs)
    font-size: 1.3em

h2,
.h2
  font-family: 'Axiforma', 'Montserrat', sans-serif
  font-weight: $bold
  font-size: 1.5em
  color: $pale-yellow
  letter-spacing: 0.8px

  @media (max-width: $breakpoint-max-xxs)
    font-size: 1.2em

  @media (max-width: $breakpoint-max-md)
    text-align: left

h3,
.h3
  font-family: 'Axiforma', 'Montserrat', sans-serif
  font-weight: $medium
  font-size: 1.2em
  line-height: 1.4em

  @media (max-width: $breakpoint-max-xxs)
    font-size: 1.1em

p
  @media (max-width: $breakpoint-max-md)
    text-align: left

.text-lg
  font-size: 1.15em

.text-md
  font-size: 1em

.text-sm
  font-size: 0.9em
  line-height: 1.5em

.text-xs
  font-size: 0.6em
  line-height: 1.6em

.text-grey
  color: $medium-grey

.text-yellow
  color: $bright-yellow

.reversed
  color: $white

.bold
  font-weight: $bold

.text-block
  max-width: 600px

.text-center
  text-align: center

.notification
  border: solid 1px $alarm
  padding: 20px 40px
  background-color: lighten($alarm, 38%)
  color: $alarm
  font-weight: $bold
  border-radius: 2px

.highlight
  background-color: $bright-yellow
  color: $dark-grey
  font-weight: $heavy
  text-transform: uppercase
  padding: 6px 6px 5px

// Forms
label
  color: $medium-grey
  font-weight: 600
  text-transform: none
  font-size: 0.8em

input
  padding: 6px 12px
  font-weight: $medium
  -webkit-appearance: none
  border: none
  outline: none
  border-radius: 4px
  background-color: $pale-yellow

  &:focus
    outline: none
    border: 0

  &::placeholder
    font-size: .9em
    font-weight: $medium
    color: rgba($dark-grey, 0.5)

.error-message
  border: solid 1px $alarm
  background-color: lighten($alarm, 50%)
  color: $alarm
  font-weight: $bold
  border-radius: 2px
  padding: 6px 15px
  font-size: 0.8em

.fade-in
  opacity: 0
  @include animation('fade-in 500ms ease-in 0s 1 normal forwards')

#homeComponent,
#accountComponent,
#mintComponent,
#premintComponent,
#tokenComponent,
#mintedverseComponent,
#noaccessComponent,
#teddyCheckerComponent,
#comingSoonComponent
  min-height: 60vh

.club-banner
  &.embed-container
    @include flex-center
    width: 100vw
    height: 300px
    overflow: hidden
    background-color: #121110

    iframe
      width: 100vw
      height: 800px

.accordion
  text-align: left
  font-family: 'Axiforma'
  background-color: transparent

  .card
    border: 0
    box-shadow: none
    background-color: transparent

    .card-header
      background-color: $black
      padding: 0

      &:after
        font-family: "Font Awesome 5 Free"
        content: "\f078"
        font-weight: 900
        color: $white
        position: absolute
        top: 20px
        right: 20px
        opacity: 0.5

      button
        font-weight: $bold
        font-size: 18px
        width: 100%
        padding: 16px
        color: $white
        text-decoration: none !important
        text-align: left
        border-bottom: solid 1px rgba($white, 0.3)
        border-radius: 0

        &.btn-link
          &:hover,
          &:active,
          &:focus
            background-color: $black

        &:focus
          box-shadow: none

    .card-body
      background-color: $black
      font-family: 'Axiforma', 'Montserrat'
      color: $white

  @media (max-width: 640px)
    .card .card-header button,
    .card .card-body
      padding-left: 0
      padding-right: 0

.close-button
  width: 26px
  height: 26px
  background-image: url('/assets/img/icon-close.svg')
  background-size: 16px
  background-position: center
  background-repeat: no-repeat
  position: absolute
  top: 30px
  right: 30px

  &:hover
    @include hover

  &:active
    @include active

.panel-content
  p
    @media (max-width: 768px)
      text-align: left

#comingSoonComponent,
#mintComponent,
#premintComponent
  .minted-bg
    bottom: 50px
    z-index: 9999

    @media (max-width: $breakpoint-max-md)
      display: block !important

    h1
      margin-left: -60px

// Panels notify fields
.notify-form-group
  input
    margin-right: 10px

  button
    margin-top: 10px
    margin-bottom: 10px

.portal-app
  overflow-x: hidden
  background-repeat: no-repeat
  width: 100%
  max-width: 375px
  margin: 0 auto
  min-height: 667px

.visually-hidden
  position: absolute !important
  overflow: hidden
  width: 1px
  height: 1px
  margin: -1px
  padding: 0
  border: 0
  clip: rect(0 0 0 0)
  word-wrap: normal !important